import React from 'react';
import { PrivacyPolicy } from '../components/organisms/PrivacyPolicy';
import Layout from '../components/layout';

type Props = {
	path: string;
};
const PrivacyPolicyPage = ({ path }: Props) => {
	return (
		<Layout>
			<PrivacyPolicy path={path} />
		</Layout>
	);
};

export default PrivacyPolicyPage;

// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--8-+nS";
export var chromeframe = "style-module--chromeframe--KQVD5";
export var errorText = "style-module--errorText--PxvlU";
export var overlay = "style-module--overlay--itoP4";
export var terms_of_use = "style-module--terms_of_use--HULZ9";
export var terms_of_use__cont = "style-module--terms_of_use__cont--p-rsK";
export var textDefault = "style-module--text-default--G7n7H";
export var textInput = "style-module--text-input--ot0K2";
export var videoJs = "style-module--video-js--LlUWJ";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--F4xVs";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--49YMy";
export var vjsPoster = "style-module--vjs-poster--mRmU9";